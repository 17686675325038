import type { ProductV2 } from "@openfoodfacts/openfoodfacts-nodejs";
import { Store } from "@tanstack/react-store";
import { localStorage } from "../local-storage";
import { recentProducts } from "./recent-products";

export const currentProduct = new Store<ProductV2 | undefined>(undefined);

export const STORAGE_KEY = "currentProduct";

currentProduct.subscribe(({ currentVal }) => {
  if (currentVal?.id) {
    localStorage?.setItem(STORAGE_KEY, JSON.stringify(currentVal));

    recentProducts.setState((prev) => {
      prev = prev ?? {};
      return { ...prev, [currentVal.id!]: currentVal };
    });
  }

  console.log(STORAGE_KEY, currentVal);
});

import type { ProductV2 } from "@openfoodfacts/openfoodfacts-nodejs";
import { Store } from "@tanstack/react-store";
import { localStorage } from "../local-storage";

export const recentProducts = new Store<Record<string, ProductV2>>({});

export const STORAGE_KEY = "recentProducts";

recentProducts.subscribe(({ currentVal }) => {
  localStorage?.setItem(STORAGE_KEY, JSON.stringify(currentVal));

  console.log(STORAGE_KEY, currentVal);
});

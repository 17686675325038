import { FireIcon } from "@heroicons/react/24/solid";
import { ProductV2 } from "@openfoodfacts/openfoodfacts-nodejs";
import { useState } from "react";
import { Modal } from "./modal";

type FoodProps = {
  product: ProductV2;
};

const decimal = (value: number, decimals: number) => Number(value.toFixed(decimals));

const Quantity = ({ className = "", value = 0, unit = "g" }: any) => {
  value = Number(value);

  if (isNaN(value)) {
    return <span className={className}>-</span>;
  }

  if (value < Number.EPSILON) {
    value = 0;
  } else if (value < 0.001) {
    value = decimal(value * 1000000, 0);
    unit = "µg";
  } else if (value < 1) {
    value = decimal(value * 1000, 0);
    unit = "mg";
  } else if (value >= 1000) {
    value = decimal(value / 1000, 3);
    unit = "kg";
  } else {
    value = decimal(value, 1);
  }

  return (
    <span className={className}>
      <span className="text-black">{value}</span>
      <span className="text-gray-500 ml-0.5">{unit}</span>
    </span>
  );
};

const countryPrefix = /^\w{2}\:/;

const FoodCard = ({ product }: FoodProps) => {
  const [zoomImage, setZoomImage] = useState(-1);

  const nutrients: Record<keyof ProductV2["nutriments"] | string, any> = product.nutriments || {};
  const macros = {
    fats: decimal(nutrients.fat_100g || 0, 1),
    carbs: decimal(nutrients.carbohydrates_100g || 0, 1),
    proteins: decimal(nutrients.proteins_100g || 0, 1),
  };

  const tags = [
    ...new Set(
      [
        ...(product.brands_tags || []),
        ...(product.categories_tags || []),
        ...(product.food_groups_tags || []),
        ...(product.labels_tags || []),
      ]
        .map((t) => t.replace(countryPrefix, ""))
        .sort()
    ),
  ];

  const pictures = [
    { alt: "front", thumbnail: product.image_thumb_url, full: product.image_url },
    {
      alt: "nutrition",
      thumbnail: product.image_nutrition_thumb_url,
      full: product.image_nutrition_url,
    },
  ].filter((pic) => !!pic.thumbnail);

  return (
    <div className="w-md max-w-screen min-h-full mx-auto rounded shadow-xl bg-white dark:bg-gray-800">
      <Modal
        visible={zoomImage >= 0}
        onClose={() => setZoomImage(-1)}
        onPrevious={
          zoomImage >= 1
            ? () => setZoomImage((prev) => (prev - 1 + pictures.length) % pictures.length)
            : undefined
        }
        onNext={
          zoomImage < pictures.length - 1
            ? () => setZoomImage((prev) => (prev + 1) % pictures.length)
            : undefined
        }
      >
        <img
          src={pictures[zoomImage]?.full}
          alt={pictures[zoomImage]?.alt}
          className="max-w-full max-h-auto select-none"
        />
      </Modal>

      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{product.product_name}</div>

        <p className="pb-4">
          {product.brands && <span className="text-gray-900">{product.brands} </span>}
          <FireIcon className="size-4 inline mx-1 -mt-1" />
          <Quantity value={nutrients["energy-kcal"]} unit={nutrients["energy-kcal_unit"]} />
        </p>

        <section className="pb-8">
          <div className="flex space-x-2 flex-wrap">
            {pictures.map(
              (pic, index) =>
                pic.thumbnail &&
                pic.full !== undefined && (
                  <div
                    key={pic.thumbnail + index}
                    className="relative w-20 aspect-square rounded cursor-pointer "
                    onClick={() => setZoomImage(index)}
                  >
                    <img
                      src={pic.thumbnail}
                      alt={pic.alt}
                      className="w-full h-full object-cover rounded select-none"
                    />
                  </div>
                )
            )}
          </div>
        </section>

        <div className=" text-gray-500 pb-4">
          <h3 className="font-semibold text-gray-900">Macros per 100g</h3>
          <ul className="ml-2 mt-2 space-y-1">
            {Object.entries(macros).map(([key, value]) => (
              <li key={key} className="flex justify-between">
                <span className="text-nowrap">{key}</span>
                <DotLeader />
                <Quantity className="float-right" value={value} />
              </li>
            ))}
          </ul>
        </div>
        <div className=" text-gray-500 pb-4">
          <h3 className="font-semibold text-gray-900">Nutrients per 100g</h3>
          <ul className="ml-2 mt-2 space-y-1">
            {Object.entries(nutrients).map(([key, value]) => {
              if (
                !key.includes("_100g") ||
                key.includes("_prepared") ||
                key.includes("carbohydrates") ||
                key.includes("fat") ||
                key.includes("proteins")
              ) {
                return null;
              }
              key = key.replace("_100g", "");
              if (nutrients[key + "_unit"] != "g") {
                return null;
              }

              return (
                value > Number.EPSILON && (
                  <li key={key} className="flex justify-between">
                    <span className="text-nowrap">{key.replaceAll("-", " ")}</span>
                    <DotLeader />
                    <Quantity value={value} />
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </div>
      <div className="px-6 py-6 text-xs">
        {1 &&
          tags.map((tag) => (
            <span
              key={tag}
              className="inline-block bg-gray-200 rounded-full px-2 py-1  text-gray-700 mr-1 mb-1"
            >
              {tag.replaceAll("-", " ")}
            </span>
          ))}
      </div>
    </div>
  );
};

export default FoodCard;

const DotLeader = () => {
  return (
    <div className="p-0 before:text-gray-300 overflow-x-hidden before:width-[0] before:float-left whitespace-nowrap before:content-['_._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._._']" />
  );
};

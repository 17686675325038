"use client";

import { useStore } from "@tanstack/react-store";
import { useRouter } from "next/navigation";
import FoodCard from "./components/foodcard";
import { currentProduct } from "./stores/current-product";
import { recentProducts } from "./stores/recent-products";

export default function Home() {
  const product = useStore(currentProduct);
  const products = useStore(recentProducts);
  const router = useRouter();

  return (
    <main className="h-full flex flex-col items-center justify-center">
      {product ? (
        <FoodCard key={product.id} product={product} />
      ) : (
        <div className="text-center">
          <p className="py-10">Please scan a product barcode.</p>
          <button
            className="inline-block bg-blue-500 rounded-4xl p-2 px-4 text-white"
            onClick={() => router.push("/scan")}
          >
            Scan product
          </button>
        </div>
      )}

      {products?.length && (
        <div className="w-full px-4 py-2">
          <h2 className="text-lg font-semibold">Recent Products</h2>
          <div className="flex flex-col space-y-4">
            {Object.keys(products).map((id: string) => (
              <FoodCard key={id} product={products[id]} />
            ))}
          </div>
        </div>
      )}
    </main>
  );
}
